
.voo-underline {
    /* font-weight: 300;
    padding-bottom: 8px;
    position: relative;
    font-size: 20px; */
    border-bottom: 3px solid #01497f;
}

.nav li, .voo-underline {
    display: inline-block;
}

.background{
    /* background: #80808014; */
}

.headerbg{
    background:#80808014;
}

.mainDivBg{
    background: rgb(248, 249, 250)
}

li {
    margin:0 0 10px 0;   
 }

 .bottomMargin{
    margin-bottom: 30px;
 }